<template>
  <div class="reset">
    <div v-if="passReset" class="pass-success">
      <span class="pass-text"> Password reset successfully! </span>
      <XIcon class="x-icon" @click="hidePopup" />
    </div>
    <div class="logo-container">
      <DigitalPassport class="digital-logo" />
    </div>
    <h1>Password Reset</h1>
    <div>
      <div class="input-holder">
        <label class="form-header"
          >PASSWORD:
          <VWFormField
            class="form-field"
            :class="{ valid: password && !mismatch }"
            placeholder="New Password"
            type="password"
            v-model="password"
            :error="isError && !password"
            :errorMessage="mismatch"
        /></label>
      </div>
      <div class="input-holder">
        <label
          ><VWFormField
            class="form-field-confirm"
            :class="{ valid: confirm && !mismatch }"
            v-model="confirm"
            :error="isError && !confirm"
            :errorMessage="mismatch"
            placeholder="Confirm New Password"
            type="password"
        /></label>
      </div>
    </div>
    <PassportButton label="Reset Password" class="reset-button" @click="reset" />
  </div>
</template>

<script>
import { ApiService } from '@/services/api.service';
import VWFormField from '@/components/VWFormField.vue';
import DigitalPassport from '@/assets/images/logo_digital_passport.svg';
import PassportButton from '@/components/PassportButton.vue';
import XIcon from '@/assets/icons/x_icon.svg';

export default {
  name: 'reset',
  components: {
    VWFormField,
    DigitalPassport,
    PassportButton,
    XIcon,
  },
  data() {
    return {
      password: '',
      confirm: '',
      isError: false,
      mismatch: '',
      passReset: false,
      token: this.$route.query.token,
    };
  },
  mounted() {},
  methods: {
    hidePopup() {
      this.passReset = false;
    },
    async reset() {
      const profile = {
        token: this.token,
        password: this.password,
      };
      this.isError = true;
      if (this.password !== this.confirm) {
        this.mismatch = 'Passwords do not match';
      } else {
        this.mismatch = '';
      }
      if (this.password && this.confirm && !this.mismatch) {
        this.isError = false;
        await ApiService.post('/user/password-reset', profile).then((response) => {
          if (response.status >= 200 && response.status < 300) {
            this.passReset = true;
            setTimeout(() => {
              this.passReset = false;
              this.$router.push('/login');
            }, 3000);
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reset {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 100%;
  font-size: 1.2rem;
  .reset-header {
    font-family: $head-font-stack;
  }

  .logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6em;
  }
  .checkbox {
    display: flex;
    align-items: flex-start;
    width: 13.5em;
    margin-top: 1.5em;
    @include bp-sm-phone-landscape {
      width: 26em;
      margin-top: 2em;
    }
  }
  ::v-deep .g-checkbox .label.after {
    margin-left: 1em;
    font-size: 18px;
    text-align: left;
  }
  ::v-deep .g-checkbox {
    display: flex;
    align-items: flex-start;
  }
  ::v-deep .passport-checkbox .checkbox {
    margin-top: 6px;
  }
  .main-logo {
    width: 3.875em;
    height: 1.875em;
    margin-top: 0.5em;
  }
  .digital-logo {
    width: 14.5em;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .reset-button {
    width: 15.625em;
    height: 3em;
    margin-top: 2em;
    font-family: $head-font-stack;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  ::v-deep .passport-button.text {
    font-size: 14px;
    text-decoration: underline;
  }
  .paragraph {
    margin: 0;
    margin-left: 5px;
    font-size: 18px;
    text-align: left;
  }
  .input-holder {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
  .form-header {
    margin-top: 1.5em;
    font-size: 12px;
    text-align: left;
    letter-spacing: 0.6px;
    @include bp-sm-phone-landscape {
      margin-top: 1em;
    }
  }
  .form-field {
    width: 257px;
    &.valid {
      background-image: url('../assets/icons/checkmark.svg?external');
      background-repeat: no-repeat;
      background-position: right;
      background-position-x: 95%;
      background-size: 15px;
    }
    @include bp-sm-phone-landscape {
      width: 500px;
    }
  }
  .form-field-confirm {
    width: 257px;
    margin-top: 1em;
    &.valid {
      background-image: url('../assets/icons/checkmark.svg?external');
      background-repeat: no-repeat;
      background-position: right;
      background-position-x: 95%;
      background-size: 15px;
    }
    @include bp-sm-phone-landscape {
      width: 500px;
    }
  }
  ::v-deep .vw-form-field .message {
    text-align: left;
  }
  .invalid {
    border-color: red;
  }
  .button-container {
    display: flex;
    flex-direction: column;
  }
  .confirmation {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-top: 1em;
    .confirmation-text {
      font-size: 18px;
      text-align: left;
      @include bp-md-tablet {
        text-align: center;
      }
    }
  }
  .log-in {
    margin-top: 1em;
  }
  .pass-success {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 94px;
    background-color: $primary-color;
  }
  .pass-text {
    margin-left: 1em;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
  }
  .x-icon {
    display: flex;
    width: 20px;
    margin-right: 1em;
  }
}
</style>
